<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="primary" @click="addVideosDialog = true"
        >新增视频</el-button
      >
    </div>
    <!-- 数据查看表格 -->
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="视频ID" width="70"> </el-table-column>
      <el-table-column prop="title" label="视频标题"> </el-table-column>
      <el-table-column prop="tag" label="视频标签">
        <template slot-scope="scope">
          <div v-for="item in parseTags(scope.row.tag)" :key="item">
            <el-tag type="primary">{{ item }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="img" label="视频封面" width="180">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.img"
            :preview-src-list="[scope.row.img]"
            style="width: 150px; height: 100px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="视频价格">
        <template slot-scope="scope">
          <span v-if="scope.row.price === 0">免费</span>
          <span v-else>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="primary" @click="checkVideo(scope.row.id)"
            >查看子视频</el-button
          >
          <el-popconfirm
            title="确定删除该视频以及所有子视频吗？"
            @confirm="delvideos(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除视频</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 查看子视频弹窗 -->
    <el-dialog
      title="查看子视频"
      :visible.sync="checkVideoDialogVisible"
      width="80%"
    >
      <el-button type="primary" @click="addVideoDialog = true"
        >上传子视频</el-button
      >
      <el-table
        :data="videoData"
        v-loading="vloading"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="视频ID"> </el-table-column>
        <el-table-column prop="vid" label="归属视频ID"> </el-table-column>
        <el-table-column prop="title" label="选集标题"> </el-table-column>
        <el-table-column prop="videourl" label="视频链接(点击复制)">
          <template slot-scope="scope">
            <span @click="copyLick(scope.row.videourl)">{{
              scope.row.videourl
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime">{{
              formatDate(scope.row.createtime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updatetime" label="修改时间">
          <template slot-scope="scope">
            <span v-if="scope.row.updatetime">{{
              formatDate(scope.row.updatetime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <!-- <el-button type="primary" @click="editVideosDialogOpen(scope.row)"
              >编辑</el-button
            > -->
            <el-popconfirm
              title="确定删除该子视频吗？"
              @confirm="delvideo(scope.row.id)"
            >
              <el-button
                type="danger"
                slot="reference"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 子视频上传弹窗 -->
    <el-dialog title="新增子视频" :visible.sync="addVideoDialog" width="30%">
      <el-form>
        <el-form-item label="选集标题">
          <el-input v-model="addVideo.title"></el-input>
        </el-form-item>
        <el-form-item label="选择视频">
          <br />
          <!-- <el-input v-model="editVideo.videourl"></el-input> -->
          <!-- 上传按钮 -->
          <el-button type="primary" @click="triggerUpload">选择视频</el-button>

          <!-- 隐藏的 el-upload 组件 -->
          <el-upload
            ref="upload"
            :show-file-list="false"
            :before-upload="handleBeforeUpload"
            accept="video/*"
          >
          </el-upload>
          <video
            v-if="videoBase64"
            width="100%"
            controls
            autoplay
            muted
            :src="videoBase64"
          ></video>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVideoDialog = false">取 消</el-button>
        <el-button
          v-if="videoBase64"
          type="primary"
          @click="uploadSchoolVideos()"
          >上 传</el-button
        >
      </span>
    </el-dialog>
    <!-- 子视频修改弹窗 -->
    <el-dialog title="修改子视频" :visible.sync="editVideosDialog" width="30%">
      <el-form>
        <el-form-item label="选集标题">
          <el-input v-model="editVideos.title"></el-input>
        </el-form-item>
        <el-form-item label="选择视频">
          <br />
          <!-- <el-input v-model="editVideo.videourl"></el-input> -->
          <!-- 上传按钮 -->
          <el-button type="primary" @click="triggerUpload">选择视频</el-button>
          <!-- 隐藏的 el-upload 组件 -->
          <el-upload
            ref="upload"
            :show-file-list="false"
            :before-upload="handleBeforeUpload"
            accept="video/*"
          >
          </el-upload>
          <video
            v-if="videoBase64"
            width="100%"
            controls
            autoplay
            muted
            :src="videoBase64"
          ></video>
          <video
            v-if="!videoBase64"
            width="100%"
            controls
            autoplay
            muted
            :src="editVideos.videourl"
          ></video>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVideosDialog = false">取 消</el-button>
        <el-button type="primary" @click="editSchoolVideos()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增视频dialog -->
    <el-dialog title="新增视频" :visible.sync="addVideosDialog" width="30%">
      <el-form label-width="70px">
        <el-form-item label="视频标题">
          <el-input v-model="addVideos.title"></el-input>
        </el-form-item>
        <el-form-item label="视频标签">
          <el-input
            v-model="addVideos.tag"
            placeholder="请输入视频标签,以逗号分隔"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频封面">
          <!-- <el-input v-model="addVideos.img"></el-input> -->
          <!-- 上传按钮 -->
          <el-button type="primary" @click="imgUpload">选择图片</el-button>
          <!-- 隐藏的 el-upload 组件 -->
          <el-upload
            ref="upload"
            :show-file-list="false"
            :before-upload="handleImgBeforeUpload"
            accept="image/*"
          >
          </el-upload>
        </el-form-item>
        <el-form-item label="视频价格">
          <el-input v-model="addVideos.price" type="number"></el-input>
        </el-form-item>
        <el-form-item label="归属分类">
          <el-radio-group
            v-loading="tloading"
            v-model="addVideos.type"
            v-for="item in types"
            :key="item.id"
          >
            <el-radio :label="item.type">{{ item.type }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <img v-if="imageBase64" :src="imageBase64" alt="" style="width: 100%" />
      <el-button @click="addVideosDialog = false">取 消</el-button>
      <el-button type="primary" @click="addvideos()">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  addvideo,
  delvideo,
  delvideos,
  editSchoolVideo,
  getSchoolVideoById,
  getSchoolVideos,
  getVideoType,
  uploadSchoolVideo,
} from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1, // 当前页码
      pageSize: 10, // 每页条数
      total: 0, // 总记录数
      videoData: [],
      vloading: false,
      checkVideoDialogVisible: false,
      editVideo: [],
      editVideoDialog: false,
      addVideo: [], // 极速子视频
      addVideoDialog: false,
      videoBase64: "",
      id: "",
      addVideosDialog: false,
      addVideos: [], // 极速视频
      imageBase64: "",
      types: [],
      tloading: false,
      editVideosDialog: false,
      editVideos: [],
    };
  },
  methods: {
    editSchoolVideos() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editSchoolVideo(
        token,
        this.editVideos.id,
        this.editVideos.title,
        this.videoBase64
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.editVideosDialog = false;
          this.getSchoolVideos();
          loading.close();
        } else {
          this.$message.error("编辑失败" + res.data.msg);
          loading.close();
        }
      });
    },
    editVideosDialogOpen(row) {
      this.editVideosDialog = true;
      this.editVideos = row;
    },
    delvideos(id) {
      this.loading = true;
      const token = localStorage.getItem("token");
      delvideo(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getSchoolVideo();
          this.loading = false;
        } else {
          this.$message.error("删除失败");
          this.loading = false;
        }
      });
    },
    addvideos() {
      if (
        (this.addVideos.title,
        this.addVideos.tag,
        this.imageBase64,
        this.addVideos.price,
        this.addVideos.type)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        addvideo(
          token,
          this.addVideos.title,
          JSON.stringify(this.addVideos.tag),
          this.imageBase64,
          this.addVideos.price,
          this.addVideos.type
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功!");
            this.addVideosDialog = false;
            this.getSchoolVideo();
            loading.close();
          } else {
            this.$message.error("添加失败!" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息!");
      }
    },
    getVideoTypes() {
      this.tloading = true;
      getVideoType().then((res) => {
        if (res.data.code == 200) {
          this.types = res.data.data;
          this.tloading = false;
        } else {
          this.$message.error("获取极速分类失败" + res.data.msg);
          this.tloading = false;
        }
      });
    },
    imgUpload() {
      this.$refs.upload.$el.querySelector("input").click();
    },
    // 在上传前处理文件，将文件转换为 base64
    handleImgBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imageBase64 = e.target.result; // 存储 base64 数据
          // this.$message.success("图片已转换为 base64 格式！");
          resolve(false); // 阻止默认上传行为
        };
        reader.onerror = (error) => {
          this.$message.error("文件读取失败！" + error);
          reject();
        };
      });
    },
    // 删除视频选集
    delvideo(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delvideos(token, id)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("删除成功");
            this.checkVideo(this.id);
            loading.close();
          } else {
            this.$message.error("删除失败" + res.data.msg);
            loading.close();
          }
        })
        .catch((err) => {
          this.$message.error("删除失败" + err.data.msg);
          loading.close();
        });
    },
    uploadSchoolVideos() {
      if ((this.id, this.addVideo.title, this.videoBase64)) {
        const loading = this.$loading({
          lock: true,
          text: "正在上传",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        uploadSchoolVideo(token, this.id, this.addVideo.title, this.videoBase64)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("上传成功");
              this.addVideoDialog = false;
              this.checkVideo(this.id);
              loading.close();
            } else {
              this.$message.error("上传失败" + res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error("上传失败" + err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息!");
      }
    },
    // 点击按钮触发上传选择文件
    triggerUpload() {
      this.$refs.upload.$el.querySelector("input").click();
    },
    // 在上传前处理文件，将文件转换为 base64
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.videoBase64 = e.target.result; // 存储 base64 数据
          resolve(false); // 阻止默认上传行为
        };
        reader.onerror = (error) => {
          this.$message.error("文件读取失败！" + error);
          reject();
        };
      });
    },
    copyLick(e) {
      navigator.clipboard.writeText(e);
      this.$message.success("复制成功");
    },
    checkVideo(id) {
      this.id = id;
      this.checkVideoDialogVisible = true;
      this.vloading = true;
      getSchoolVideoById(this.id)
        .then((res) => {
          if (res.data.code === 200) {
            this.videoData = res.data.data;
            this.vloading = false;
          } else {
            this.$message.error("获取子视频失败" + res.data.msg);
            this.vloading = false;
          }
        })
        .catch((err) => {
          this.$message.error("出现错误" + err.data.msg);
          this.vloading = false;
        });
    },
    parseTags(tag) {
      // 如果 tag 是字符串形式的数组，使用 JSON.parse 转换
      return typeof tag === "string" ? JSON.parse(tag) : tag;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getSchoolVideo(); // 请求新页面的数据
    },
    getSchoolVideo() {
      this.loading = true;
      getSchoolVideos(this.page, this.pageSize)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error("获取视频列表失败" + res.data.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("出现错误" + err.data.msg);
          this.loading = false;
        });
    },
  },
  created() {
    this.getSchoolVideo();
    this.getVideoTypes();
  },
};
</script>

<style></style>
