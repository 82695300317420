<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button
        type="success"
        @click="
          (dialogVisible = true),
            (form = []),
            (type = 1),
            (form.video = ''),
            (customFileList = [])
        "
        >+新增接口游戏</el-button
      >
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="game_name" label="接口游戏名称"> </el-table-column>
      <el-table-column prop="game_icon" label="接口游戏图标">
        <template slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.game_icon"
            :preview-src-list="[scope.row.game_icon]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="introduce" label="接口游戏介绍"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="(dialogVisible = true), (form = scope.row), (type = 2)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delInterfaceGames(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑/新增弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="75%">
      <el-row :gutter="20">
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="接口游戏名称">
              <el-input v-model="form.game_name"></el-input>
            </el-form-item>
            <el-form-item label="接口游戏介绍">
              <el-input v-model="form.introduce"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="游戏截图(游戏未开始.主界面)">
              <br />
              <ImageUploader
                v-model="form.img1"
                upload-url="https://api.jisuqipai.online/api/user/uploadImg"
              />
            </el-form-item>
            <el-form-item label="游戏截图2(游戏中界面)">
              <br />
              <ImageUploader
                v-model="form.img2"
                upload-url="https://api.jisuqipai.online/api/user/uploadImg"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="游戏截图3(开奖.中奖)">
              <br />
              <ImageUploader
                v-model="form.img3"
                upload-url="https://api.jisuqipai.online/api/user/uploadImg"
              />
            </el-form-item>
            <el-form-item label="接口游戏图标">
              <br />
              <ImageUploader
                v-model="form.game_icon"
                upload-url="https://api.jisuqipai.online/api/user/uploadImg"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="游戏视频预览">
              <br />
              <el-upload
                class="upload-demo"
                action="https://api.jisuqipai.online/api/admin/uploadGameVideo"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :file-list="customFileList"
                :on-remove="handleRemove"
                :data="extraParams"
                :headers="headers"
                accept=".mp4"
                :limit="3"
                :on-exceed="handleExceed"
              >
                <el-button
                  size="small"
                  type="primary"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
          <video
            :src="form.video"
            style="width: 300px"
            autoplay
            muted
            controls
          ></video>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" type="primary" @click="addInterfaceGames()"
          >新 增</el-button
        >
        <el-button
          v-if="type === 2"
          type="primary"
          @click="editInterfaceGames()"
          >编 辑</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  addInterfaceGame,
  delInterfaceGame,
  editInterfaceGame,
  getInterFaceGameList,
} from "@/api/api";
import ImageUploader from "./ImageUploader.vue";
export default {
  components: { ImageUploader },
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogVisible: false,
      form: [],
      type: 1, // 1:新增 2:编辑
      allowedTypes: [
        "video/mp4", // MP4
        "video/x-msvideo", // AVI
        "video/quicktime", // MOV
      ],
      customFileList: [],
    };
  },
  methods: {
    delInterfaceGames(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delInterfaceGame(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getInterFaceGameLists();
          loading.close();
          return;
        }
        this.$message.error("删除失败" + res.data.msg);
        loading.close();
      });
    },
    editInterfaceGames() {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editInterfaceGame(
        token,
        this.form.id,
        this.form.game_name,
        this.form.introduce,
        this.form.img1,
        this.form.img2,
        this.form.img3,
        this.form.game_icon,
        this.form.video
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功！");
          this.getInterFaceGameLists();
          this.dialogVisible = false;
          loading.close();
          return;
        }
        this.$message.error("修改失败" + res.data.msg);
        loading.close();
      });
    },
    addInterfaceGames() {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addInterfaceGame(
        token,
        this.form.game_name,
        this.form.introduce,
        this.form.img1,
        this.form.img2,
        this.form.img3,
        this.form.game_icon,
        this.form.video
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("新增成功！");
          this.getInterFaceGameLists();
          this.dialogVisible = false;
          loading.close();
          return;
        }
        this.$message.error("新增失败" + res.data.msg);
        loading.close();
      });
    },
    // 上传前检查文件类型和大小
    beforeUpload(file) {
      if (this.customFileList.length > 0) {
        this.$message.error("只能上传一个文件");
        return false;
      }
      this.fullscreenLoading = true;
      const isAllowedType = this.allowedTypes.includes(file.type);
      const isLt100M = file.size / 1024 / 1024 < 100;

      if (!isAllowedType) {
        this.$message.error("只能上传 ZIP 或图片文件");
        return false;
      }
      if (!isLt100M) {
        this.$message.error("文件大小不能超过 100MB");
        return false;
      }
      return true;
    },

    // 上传成功回调
    handleSuccess(response, file) {
      if (response.code === 200) {
        this.$message.success("文件上传成功");
        this.fullscreenLoading = false;
        // 将文件信息插入到自定义列表中
        this.customFileList.push({
          name: file.name, // 原文件名
          url: response.data.relative_path, // 文件路径
        });
        this.form.video = response.data.relative_path;
      } else {
        this.$message.error("上传失败: " + response.message);
        this.fullscreenLoading = false;
      }
    },

    // 文件移除回调
    handleRemove(file) {
      // 从 customFileList 中移除被删除的文件
      this.customFileList = this.customFileList.filter(
        (item) => item.url !== file.url
      );
    },

    // 文件超出限制
    handleExceed() {
      this.$message.warning("文件数量超出限制");
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getInterFaceGameLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getInterFaceGameList(token, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getInterFaceGameLists(); // 请求新页面的数据
    },
  },
  created() {
    this.getInterFaceGameLists(); // 请求新页面的数据
  },
};
</script>

<style></style>
