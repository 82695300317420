<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="need"
        placeholder="请选择需求类型"
        @change="getServeLists()"
      >
        <el-option
          v-for="item in list"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="uid" label="用户ID" width="80"> </el-table-column>
      <el-table-column prop="pid" label="用户上级ID" width="80">
      </el-table-column>
      <el-table-column prop="game" label="游戏产品"> </el-table-column>
      <el-table-column prop="type" label="换皮方式">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">全新设计</span>
          <span v-if="scope.row.type == 2">模仿产品</span>
        </template>
      </el-table-column>
      <el-table-column prop="contact" label="联系方式"> </el-table-column>
      <el-table-column prop="info" label="需求描述"> </el-table-column>
      <el-table-column prop="img" label="附件图片">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.img"
            :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="tec" label="技术需求"> </el-table-column>
      <el-table-column prop="need" label="需求类型"> </el-table-column>
      <el-table-column prop="createtime" label="订单创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="deleteServices(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑服务" :visible.sync="dialogVisible" width="80%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="游戏产品">
              <el-input v-model="form.game"></el-input>
            </el-form-item>
            <el-form-item label="换皮方式">
              <el-input v-model="form.type"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="form.contact"></el-input>
            </el-form-item>
            <el-form-item label="需求描述">
              <el-input v-model="form.info"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="技术需求">
              <el-input v-model="form.tec"></el-input>
            </el-form-item>
            <el-form-item label="需求类型">
              <el-input v-model="form.need"></el-input>
            </el-form-item>
            <el-form-item label="附件图片">
              <el-upload
                class="avatar-uploader"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
                v-loading="img_loading"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateServices()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteService,
  getServeList,
  updateService,
  uploadImg,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1, // 当前页码
      pageSize: 10, // 每页条数
      total: 0, // 总记录数
      dialogVisible: false,
      form: [],
      progress: 0,
      imageUrl: "",
      need: "",
      list: [
        {
          id: 1,
          name: "二次开发",
        },
        {
          id: 2,
          name: "外包维护",
        },
        {
          id: 3,
          name: "游戏换皮",
        },
      ],
    };
  },
  methods: {
    updateServices() {
      if (
        (this.form.id,
        this.form.game,
        this.form.type,
        this.form.contact,
        this.form.info,
        this.form.tec,
        this.form.need,
        this.imageUrl)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在修改",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        updateService(
          this.form.id,
          this.form.game,
          this.form.type,
          this.form.contact,
          this.form.info,
          this.form.tec,
          this.form.need,
          this.imageUrl
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.getServeLists();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("修改失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请完整填写信息!");
      }
    },
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
    openEditDialog(row) {
      this.form = row;
      this.imageUrl = row.img;
      this.dialogVisible = true;
    },
    deleteServices(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      deleteService(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getServeLists();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getServeLists(); // 请求新页面的数据
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getServeLists() {
      this.loading = true;
      getServeList(this.page, this.pageSize, this.need).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取数据失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getServeLists();
  },
};
</script>

<style></style>
