<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-input
        clearable
        v-model="orderno"
        @change="getRechargeLists()"
        placeholder="请输入订单号或外部订单号"
        style="width: 300px"
      ></el-input>
      <el-date-picker
        v-model="timeRange"
        type="daterange"
        align="top"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        @change="getRechargeLists()"
        style="margin-left: 10px"
      >
      </el-date-picker>
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="user_id" label="用户ID" width="70">
      </el-table-column>
      <el-table-column prop="order_sn" label="订单号"> </el-table-column>
      <el-table-column prop="out_order_sn" label="外部订单号">
      </el-table-column>
      <el-table-column prop="amount" label="总金额"> </el-table-column>
      <el-table-column prop="status" label="订单状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="warning">待支付</el-tag>
          <el-tag v-if="scope.row.status === 2" type="warning">支付完成</el-tag>
          <el-tag v-if="scope.row.status === 3" type="warning">已过期</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="下单时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="pay_time" label="支付时间">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_time">{{
            formatDate(scope.row.pay_time)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delTickets(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getRechargeList } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeRange: [],
      orderno: "",
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getRechargeLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      let startTime = "";
      let endTime = "";
      if (this.timeRange) {
        startTime = this.timeRange[0];
        endTime = this.timeRange[1];
      }
      getRechargeList(
        token,
        this.page,
        this.pageSize,
        this.orderno,
        startTime,
        endTime
      ).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getRechargeLists(); // 请求新页面的数据
    },
  },
  created() {
    this.getRechargeLists();
  },
};
</script>

<style></style>
