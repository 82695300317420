import Vue from "vue";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/router.js";
import App from "./App.vue";
import VueQuillEditor from "vue-quill-editor";

// 引入 Quill 样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css"; // 白色主题
import "quill/dist/quill.bubble.css"; // 气泡主题

// Vue.use(TDesign);
Vue.use(ElementUI);
// 使用插件
Vue.use(VueQuillEditor);

// 配置axios全局使用
Vue.prototype.$http = axios;

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
