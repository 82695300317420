<template>
  <el-upload
    class="avatar-uploader"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-change="handleChange"
  >
    <img v-if="value" :src="value" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null, // 图片的初始值
    },
    uploadUrl: {
      type: String,
      required: true, // 上传接口 URL
    },
  },
  methods: {
    // beforeUpload(file) {
    //   const isJPG = file.type === "image/jpeg";
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error("上传图片只能是 JPG 格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("图片大小不能超过 2MB!");
    //   }
    //   return isJPG && isLt2M;
    // },
    handleChange(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = () => {
        const base64Image = reader.result;
        this.uploadToServer(base64Image); // 上传 Base64 数据
      };
    },
    uploadToServer(base64Image) {
      const payload = { img: base64Image };
      this.$http
        .post(this.uploadUrl, payload)
        .then((response) => {
          if (response.data && response.data.data) {
            this.$emit("input", response.data.data); // 更新父组件绑定的值
            this.$message.success("图片上传成功!");
          } else {
            this.$message.error("上传成功，但未返回图片地址!");
          }
        })
        .catch((error) => {
          console.error("上传失败:", error);
          this.$message.error("图片上传失败!");
        });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  display: inline-block;
  width: 100px;
  height: 100px;
}
.avatar {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: block;
}
.el-icon-plus {
  font-size: 28px;
  color: #8c939d;
}
</style>
