<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button
        type="primary"
        @click="(form = []), (imageUrl = ''), (dialogVisible = true)"
        >+添加分类</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="title" label="显示标题"> </el-table-column>
      <el-table-column prop="image_url" label="显示图标">
        <template slot-scope="scope">
          <el-image
            style="width: 80px; height: 50px; border-radius: 5px"
            :src="scope.row.image_url"
            :preview-src-list="[scope.row.image_url]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="priority" label="优先级"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="最后修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">{{
            formatDate(scope.row.update_time)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="编辑" width="320">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="deleteServerCategorys(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
          <el-button
            type="success"
            style="margin-left: 10px"
            @click="openServerDialog(scope.row.id)"
            >配置云服务器</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加/修改弹窗 -->
    <el-dialog
      :title="type === 1 ? '编辑服务器分类' : '添加服务器分类'"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form label-width="80px">
        <el-form-item label="显示标题">
          <el-input
            v-model="form.title"
            placeholder="如：美国服务器"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先级">
          <el-input
            v-model="form.priority"
            placeholder="优先级越小越靠前"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示图标">
          <el-upload
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="type === 1"
          type="primary"
          @click="updateServerCategorys()"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="addServerCategorys()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 配置云服务器弹窗 -->
    <el-dialog title="云服务器配置" :visible.sync="serverDialog" fullscreen>
      <el-button
        type="primary"
        @click="(server = []), (type = 0), (serverDialogVisible = true)"
        >+添加云服务器配置</el-button
      >
      <el-table
        :data="serverData"
        v-loading="serverLoading"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="50"> </el-table-column>
        <el-table-column prop="region" label="服务器区域"> </el-table-column>
        <el-table-column prop="left_title" label="显示标题"> </el-table-column>
        <el-table-column prop="left_desc" label="左边下部描述">
        </el-table-column>
        <el-table-column prop="cpu_cores" label="CPU核心数"> </el-table-column>
        <el-table-column prop="ram_size" label="内存大小(单位:MB)">
        </el-table-column>
        <el-table-column prop="broadband" label="宽带(单位:M)">
        </el-table-column>
        <el-table-column prop="disk_size" label="磁盘大小(单位:GB)">
        </el-table-column>
        <el-table-column prop="month_mark_price" label="月付标价">
        </el-table-column>
        <el-table-column prop="month_real_price" label="月付真实价格">
        </el-table-column>
        <el-table-column prop="year_mark_price" label="年付标价">
        </el-table-column>
        <el-table-column prop="year_real_price" label="年付真实价格">
        </el-table-column>
        <el-table-column prop="op_system" label="可选操作系统">
        </el-table-column>
        <el-table-column prop="priority" label="优先级"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" @click="openServerEditDialog(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delServers(scope.row.id)"
            >
              <el-button
                type="danger"
                slot="reference"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="serverDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 添加/修改云服务器弹窗 -->
    <el-dialog
      :title="type === 1 ? '编辑服务器配置' : '添加服务器配置'"
      :visible.sync="serverDialogVisible"
      width="80%"
    >
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form label-width="130px">
            <el-form-item label="区域">
              <el-input v-model="server.region"></el-input>
            </el-form-item>
            <el-form-item label="显示标题">
              <el-input v-model="server.left_title"></el-input>
            </el-form-item>
            <el-form-item label="显示描述">
              <el-input v-model="server.left_desc"></el-input>
            </el-form-item>
            <el-form-item label="CPU核心数">
              <el-input v-model="server.cpu_cores"></el-input>
            </el-form-item>
            <el-form-item label="内存大小(单位:MB)">
              <el-input v-model="server.ram_size"></el-input>
            </el-form-item>
            <el-form-item label="宽带(单位:M)">
              <el-input v-model="server.broadband"></el-input>
            </el-form-item>
            <el-form-item label="磁盘大小(单位:GB)">
              <el-input v-model="server.disk_size"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form label-width="130px">
            <el-form-item label="月付标价">
              <el-input v-model="server.month_mark_price"></el-input>
            </el-form-item>
            <el-form-item label="月付真实价格">
              <el-input v-model="server.month_real_price"></el-input>
            </el-form-item>
            <el-form-item label="年付标价">
              <el-input v-model="server.year_mark_price"></el-input>
            </el-form-item>
            <el-form-item label="年付真实价格">
              <el-input v-model="server.year_real_price"></el-input>
            </el-form-item>
            <el-form-item label="可选操作系统">
              <el-input v-model="server.op_system"></el-input>
            </el-form-item>
            <el-form-item label="排序优先级">
              <el-input v-model="server.priority"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="serverDialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" type="primary" @click="updateServers()"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="addServers()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addServer,
  addServerCategory,
  deleteServerCategory,
  delServer,
  getServerCategoryList,
  getServerList,
  updateServer,
  updateServerCategory,
  uploadImg,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      imageUrl: "",
      progress: 0,
      form: [],
      type: 0,
      serverData: [],
      serverLoading: false,
      serverDialog: false,
      serverDialogVisible: false,
      server: [],
      serverid: "",
    };
  },
  methods: {
    delServers(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      delServer(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getServerLists(this.serverid);
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    addServers() {
      if (
        (this.server.region,
        this.server.left_title,
        this.server.left_desc,
        this.server.cpu_cores,
        this.server.ram_size,
        this.server.broadband,
        this.server.disk_size,
        this.server.month_mark_price,
        this.server.month_real_price,
        this.server.year_mark_price,
        this.server.year_real_price,
        this.server.op_system,
        this.server.priority,
        this.serverid)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addServer(
          this.server.region,
          this.server.left_title,
          this.server.left_desc,
          this.server.cpu_cores,
          this.server.ram_size,
          this.server.broadband,
          this.server.disk_size,
          this.server.month_mark_price,
          this.server.month_real_price,
          this.server.year_mark_price,
          this.server.year_real_price,
          this.server.op_system,
          this.server.priority,
          this.serverid
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.getServerLists(this.serverid);
            this.serverDialogVisible = false;
            loading.close();
          } else {
            this.$message.error("添加失败" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    updateServers() {
      if (
        (this.server.id,
        this.server.region,
        this.server.left_title,
        this.server.left_desc,
        this.server.cpu_cores,
        this.server.ram_size,
        this.server.broadband,
        this.server.disk_size,
        this.server.month_mark_price,
        this.server.month_real_price,
        this.server.year_mark_price,
        this.server.year_real_price,
        this.server.op_system,
        this.server.priority,
        this.server.category)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        updateServer(
          this.server.id,
          this.server.region,
          this.server.left_title,
          this.server.left_desc,
          this.server.cpu_cores,
          this.server.ram_size,
          this.server.broadband,
          this.server.disk_size,
          this.server.month_mark_price,
          this.server.month_real_price,
          this.server.year_mark_price,
          this.server.year_real_price,
          this.server.op_system,
          this.server.priority,
          this.server.category
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.getServerLists(this.serverid);
            this.serverDialogVisible = false;
            loading.close();
          } else {
            this.$message.error("修改失败" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    openServerEditDialog(row) {
      this.server = row;
      this.type = 1;
      this.serverDialogVisible = true;
    },
    openServerDialog(id) {
      this.serverid = id;
      this.getServerLists(id);
      this.serverDialog = true;
    },
    getServerLists(id) {
      this.serverLoading = true;
      getServerList(id).then((res) => {
        if (res.data.code === 200) {
          this.serverData = res.data.data;
          this.serverLoading = false;
        } else {
          this.$message.error("获取数据失败！" + res.data.msg);
          this.serverLoading = false;
        }
      });
    },
    deleteServerCategorys(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      deleteServerCategory(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getServerCategoryLists();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    updateServerCategorys() {
      if ((this.form.id, this.form.title, this.form.priority, this.imageUrl)) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        updateServerCategory(
          this.form.id,
          this.form.title,
          this.form.priority,
          this.imageUrl
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功");
            this.getServerCategoryLists();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("修改失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    openEditDialog(row) {
      this.type = 1;
      this.form = row;
      this.imageUrl = row.image_url;
      this.dialogVisible = true;
    },
    addServerCategorys() {
      if ((this.form.title, this.form.priority, this.imageUrl)) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addServerCategory(
          this.form.title,
          this.form.priority,
          this.imageUrl
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功");
            this.getServerCategoryLists();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("添加失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getServerCategoryLists() {
      this.loading = true;
      getServerCategoryList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("获取服务器类型列表失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getServerCategoryLists();
  },
};
</script>

<style></style>
