<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
    "
  >
    <div style="width: 350px; text-align: center">
      <img src="@/assets/logo1.png" alt="Logo" style="width: 300px" />
      <el-card style="margin-top: 10px">
        <el-form label-width="60px">
          <el-form-item label="用户名">
            <el-input v-model="username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="password"></el-input>
          </el-form-item>
          <el-button style="width: 100%" type="primary" @click="Logins()"
            >登录</el-button
          >
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/api";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    Logins() {
      if ((this.username, this.password)) {
        const loading = this.$loading({
          lock: true,
          text: "正在登录",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        Login(this.username, this.password)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("登录成功！");
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("username", res.data.data.username);
              localStorage.setItem("avatar", res.data.data.avatar);
              localStorage.setItem("nickname", res.data.data.nickname);
              // localStorage.setItem("isLoggedIn", "true");
              this.$router.push("/");
              loading.close();
            } else {
              loading.close();
              this.$message.error("登录失败！" + res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error("登录失败！" + err.data.msg);
            console.log(err);
            loading.close();
          });
      } else {
        this.$message.error("请输入用户名和密码！");
      }
    },
  },
  created() {},
};
</script>

<style></style>
