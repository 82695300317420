<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button
        type="primary"
        @click="
          (type = 0), (form = []), (imageUrl = ''), (dialogVisible = true)
        "
        >+添加产品</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="title" label="产品标题" width="180">
      </el-table-column>
      <el-table-column prop="description" label="产品描述"> </el-table-column>
      <el-table-column prop="image_url" label="图标URL">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px; border-radius: 50px"
            :src="scope.row.image_url"
            :preview-src-list="[scope.row.image_url]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="jump_url" label="转跳路由或URL"> </el-table-column>
      <el-table-column prop="priority" label="优先级"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="deleteProducts(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加/修改弹窗 -->
    <el-dialog
      :title="type == 1 ? '修改产品' : '添加产品'"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form label-width="120px">
        <el-form-item label="产品标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="产品描述">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="转跳路由或URL">
          <el-input
            v-model="form.jump_url"
            placeholder="(如果是URL请加http://)"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先级">
          <el-input v-model="form.priority" type="number"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-upload
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            v-loading="img_loading"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type == 1" type="primary" @click="updateProducts()"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="addProducts()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addProduct,
  deleteProduct,
  getProduct,
  updateProduct,
  uploadImg,
} from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      form: [],
      dialogVisible: false,
      progress: 0,
      imageUrl: "",
    };
  },
  methods: {
    updateProducts() {
      if (
        (this.form.id,
        this.form.title,
        this.form.description,
        this.imageUrl,
        this.form.jump_url,
        this.form.priority)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        updateProduct(
          this.form.id,
          this.form.title,
          this.form.description,
          this.imageUrl,
          this.form.jump_url,
          this.form.priority
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功");
            this.getProducts();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("修改失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请完整填写信息!");
      }
    },
    deleteProducts(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      deleteProduct(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功!");
          this.getProducts();
          loading.close();
        } else {
          this.$message.error("删除失败!" + res.data.msg);
          loading.close();
        }
      });
    },
    openEditDialog(row) {
      this.type = 1;
      this.form = row;
      this.imageUrl = row.image_url;
      this.dialogVisible = true;
    },
    addProducts() {
      if (
        (this.form.title,
        this.form.description,
        this.imageUrl,
        this.form.jump_url,
        this.form.priority)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addProduct(
          this.form.title,
          this.form.description,
          this.imageUrl,
          this.form.jump_url,
          this.form.priority
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.getProducts();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("添加失败！");
            loading.close();
          }
        });
      } else {
        this.$message.error("请完整填写信息！");
      }
    },
    getProducts() {
      this.loading = true;
      getProduct().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("获取产品列表失败" + res.data.msg);
          this.loading = false;
        }
      });
    },
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
  },
  created() {
    this.getProducts();
  },
};
</script>

<style></style>
