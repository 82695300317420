<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button
        type="primary"
        @click="
          (form = []), (imageUrl = ''), (type = 1), (dialogVisible = true)
        "
        >+新增接口文档</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="title" label="文档菜单标题"> </el-table-column>
      <el-table-column prop="fid" label="父菜单id"> </el-table-column>
      <el-table-column prop="content" label="文档内容">
        <template slot-scope="scope">
          <span
            style="
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ scope.row.content }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="is_show" label="是否展示">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show == 1" type="success">展示</el-tag>
          <el-tag v-else-if="scope.row.is_show == 0" type="success"
            >隐藏</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="(dialogVisible = true), (form = scope.row), (type = 2)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delApidocs(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 新增/编辑弹窗 -->
    <el-dialog title="新增接口文档" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="100px" label-position="left">
        <el-form-item label="接口文档标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="父接口文档">
          <el-select
            v-model="form.fid"
            placeholder="请选择(留空为置顶文档)"
            clearable
          >
            <el-option
              v-for="item in filteredTableData"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示">
          <el-radio-group v-model="form.is_show">
            <el-radio :label="0">隐藏</el-radio>
            <el-radio :label="1">展示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="contentDialogVisible = true"
          >编辑文档内容</el-button
        >
        <el-button v-if="type === 1" type="primary" @click="addApidocs()"
          >新 增</el-button
        >
        <el-button
          v-else-if="type === 2"
          type="primary"
          @click="updateApidocs()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
    <!-- 新闻内容编辑弹窗 -->
    <el-dialog
      title="新闻内容"
      :visible.sync="contentDialogVisible"
      width="30%"
    >
      <quill-editor
        v-model="form.content"
        ref="myQuillEditor"
        :options="editorOptions"
        @blur="onEditorBlur"
        @focus="onEditorFocus"
        @change="onEditorChange"
      ></quill-editor>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="contentDialogVisible = false"
          >完 成</el-button
        >
        <!-- <el-button v-else-if="type === 2" type="primary" @click="updateApidocs"
          >修 改</el-button
        > -->
        <!-- <el-button v-else type="primary" @click="addNewss()">新 增</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { addApidoc, delApidoc, getApidoc, updateApidoc } from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      form: [],
      dialogVisible: false,
      progress: "",
      img_loading: false,
      imageUrl: "",
      editorOptions: {
        placeholder: "请输入内容...",
        theme: "snow", // 主题：'snow' 或 'bubble'
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗、斜体、下划线、删除线
            ["blockquote", "code-block"], // 引用、代码块
            [{ header: 1 }, { header: 2 }], // 标题 1、标题 2
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 下标、上标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文字方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题级别
            [{ color: [] }, { background: [] }], // 字体颜色、背景颜色
            [{ font: [] }], // 字体
            [{ align: [] }], // 对齐方式
            ["link", "image", "video"], // 插入链接、图片、视频
            ["clean"], // 清除格式
          ],
        },
      },
      contentDialogVisible: false,
      type: 1, // 1:新增 2:编辑
    };
  },
  methods: {
    delApidocs(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delApidoc(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getApidocs();
          loading.close();
          return;
        }
        this.$message.error("删除失败！" + res.data.msg);
        loading.close();
      });
    },
    addApidocs() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addApidoc(
        token,
        this.form.title,
        this.form.fid,
        this.form.is_show,
        this.form.content
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("新增成功！");
          this.getApidocs();
          this.dialogVisible = false;
          loading.close();
          return;
        }
        this.$message.error("新增失败！" + res.data.msg);
        loading.close();
      });
    },
    updateApidocs() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      updateApidoc(
        token,
        this.form.id,
        this.form.title,
        this.form.fid,
        this.form.is_show,
        this.form.content
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功！");
          this.getApidocs();
          this.dialogVisible = false;
          loading.close();
          return;
        }
        this.$message.error("修改失败！" + res.data.msg);
        loading.close();
      });
    },
    openEditDialog(row) {
      this.type = "2";
      this.form = row;
      this.imageUrl = row.cover_image;
      this.dialogVisible = true;
    },
    editContent(row) {
      this.form = row;
      this.imageUrl = row.cover_image;
      this.contentDialogVisible = true;
    },
    onEditorBlur() {
      console.log("Editor blur event");
    },
    onEditorFocus() {
      console.log("Editor focus event");
    },
    onEditorChange(content) {
      console.log("内容发生变化:", content);
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getApidocs(); // 请求新页面的数据
    },
    getApidocs() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getApidoc(token, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getApidocs();
  },
  computed: {
    filteredTableData() {
      // 返回过滤后的 tableData，其中 fid 为 null 的项
      return this.tableData.filter((item) => item.fid === null);
    },
  },
};
</script>

<style></style>
