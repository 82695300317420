<template>
  <div>
    <!-- 数据总览 -->
    <div v-loading="loading" style="margin: 10px">
      <el-row :gutter="10">
        <el-col :span="8" :offset="0">
          <el-card style="text-align: center">
            <p>会员总数量</p>
            <h1 style="color: red" @click="sendMessage(2)">
              {{ data.memberTotalCount }}
            </h1>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card style="text-align: center">
            <p>今日新增会员数量</p>
            <h1 style="color: red" @click="sendMessage(2)">
              {{ data.memberTodayCount }}
            </h1>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card style="text-align: center">
            <p>代理总数量</p>
            <h1 style="color: red" @click="sendMessage(3)">
              {{ data.agentTotalCount }}
            </h1>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="margin-top: 10px">
        <el-col :span="8" :offset="0">
          <el-card style="text-align: center">
            <p>今日新增代理数量</p>
            <h1 style="color: red" @click="sendMessage(3)">
              {{ data.agentTodayCount }}
            </h1>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card style="text-align: center">
            <p>会员总充值金额</p>
            <h1 style="color: red" @click="sendMessage(6)">
              {{ data.memberTotalRecharge }}
            </h1>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card style="text-align: center">
            <p>今日会员充值金额</p>
            <h1 style="color: red" @click="sendMessage(6)">
              {{ data.memberTodayRecharge }}
            </h1>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { dashboard } from "@/api/api";

export default {
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  methods: {
    sendMessage(e) {
      this.$emit("message", e); // 触发事件并传递参数
    },
    dashboards() {
      this.loading = true;
      dashboard().then((res) => {
        if (res.data.code === 200) {
          this.data = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("数据获取失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.dashboards();
  },
};
</script>

<style></style>
