<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button
        type="primary"
        @click="
          (form = []), (imageUrl = ''), (type = ''), (dialogVisible = true)
        "
        >+新增新闻</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="cover_image" label="封面图片">
        <template slot-scope="scope">
          <el-image
            style="width: 150px; height: 70px"
            :src="scope.row.cover_image"
            :preview-src-list="[scope.row.cover_image]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="最后更新时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="warning"
            @click="editContent(scope.row), (type = '2')"
            >编辑新闻</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="deleteNewss(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增弹窗 -->
    <el-dialog title="添加新闻" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="80px">
        <el-form-item label="新闻标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="封面图片">
          <el-upload
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            v-loading="img_loading"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="success" @click="contentDialogVisible = true"
          >编辑新闻内容</el-button
        >
        <el-button v-if="type === '2'" type="primary" @click="editNewss()"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="addNewss()">新 增</el-button>
      </span>
    </el-dialog>
    <!-- 新闻内容编辑弹窗 -->
    <el-dialog
      title="新闻内容"
      :visible.sync="contentDialogVisible"
      width="30%"
    >
      <quill-editor
        v-model="form.content"
        ref="myQuillEditor"
        :options="editorOptions"
        @blur="onEditorBlur"
        @focus="onEditorFocus"
        @change="onEditorChange"
      ></quill-editor>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="type === '2'" type="primary" @click="editNewss()"
          >修 改</el-button
        >
        <!-- <el-button v-else type="primary" @click="addNewss()">新 增</el-button> -->
        <el-button v-else type="success" @click="contentDialogVisible = false"
          >完 成</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addNews,
  deleteNews,
  editNews,
  getNewsList,
  uploadImg,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      form: [],
      dialogVisible: false,
      progress: "",
      img_loading: false,
      imageUrl: "",
      editorOptions: {
        placeholder: "请输入内容...",
        theme: "snow", // 主题：'snow' 或 'bubble'
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗、斜体、下划线、删除线
            ["blockquote", "code-block"], // 引用、代码块
            [{ header: 1 }, { header: 2 }], // 标题 1、标题 2
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 下标、上标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文字方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题级别
            [{ color: [] }, { background: [] }], // 字体颜色、背景颜色
            [{ font: [] }], // 字体
            [{ align: [] }], // 对齐方式
            ["link", "image", "video"], // 插入链接、图片、视频
            ["clean"], // 清除格式
          ],
        },
      },
      contentDialogVisible: false,
      type: "",
    };
  },
  methods: {
    deleteNewss(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      deleteNews(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getNewsLists();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    openEditDialog(row) {
      this.type = "2";
      this.form = row;
      this.imageUrl = row.cover_image;
      this.dialogVisible = true;
    },
    editNewss() {
      if ((this.form.id, this.form.title, this.imageUrl, this.form.content)) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        editNews(
          this.form.id,
          this.form.title,
          this.imageUrl,
          this.form.content
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.getNewsLists();
            this.dialogVisible = false;
            this.contentDialogVisible = false;
            loading.close();
          } else {
            this.$message.error("修改失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    addNewss() {
      if ((this.form.title, this.imageUrl, this.form.content)) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addNews(this.form.title, this.imageUrl, this.form.content).then(
          (res) => {
            if (res.data.code === 200) {
              this.$message.success("添加成功！");
              this.dialogVisible = false;
              this.contentDialogVisible = false;
              this.getNewsLists();
              loading.close();
            } else {
              this.$message.error("添加失败" + res.data.msg);
              loading.close();
            }
          }
        );
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    editContent(row) {
      this.form = row;
      this.imageUrl = row.cover_image;
      this.contentDialogVisible = true;
    },
    onEditorBlur() {
      console.log("Editor blur event");
    },
    onEditorFocus() {
      console.log("Editor focus event");
    },
    onEditorChange(content) {
      console.log("内容发生变化:", content);
    },
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getNewsLists() {
      this.loading = true;
      getNewsList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("获取新闻列表失败" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getNewsLists();
  },
};
</script>

<style></style>
